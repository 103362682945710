<template>
  <FocusTrap>
    <div class="card" id="mycard">

      <ReportViewer v-if="reppath" v-bind:json_data = jsondata v-bind:reportfilename="filename" v-bind:report_url="reppath"></ReportViewer>

      <!-- Clickable menu -->
      <ul class="fab-menu fab-menu-fixed fab-menu-bottom-right is_stuck" data-fab-toggle="hover" z-index="0">
        <li>
          <a class="fab-menu-btn btn bg-primary-600 btn-float rounded-round btn-icon" @click="showFilterWindow">
            <i class="fab-icon-open icon-filter3"></i>
            <i class="fab-icon-close icon-filter4"></i>
          </a>
        </li>
      </ul>
      <!-- /clickable menu -->

      <FocusTrap>
        <vmodal name="filter_window" transition="nice-modal-fade" :delay="100" :resizable="true" :width="575" height="280" @opened="afterfilterwindow_visible">
          <form>
            <div class="card">

              <div class="card-header header-elements-inline bg-teal-700" style="padding: 10px;">
                <h3 class="card-title">Register Filters</h3>
                <div class="header-elements">
                  <div class="list-icons">
                    <!--<a class="list-icons-item" data-action="remove" @click="closeRequest"></a>-->
                  </div>
                </div>
              </div>

              <div class="card-body p-2">

                <div class="form-group row">
                  <label class="col-md-2 col-form-label">From</label>
                  <div class="col-md-4">
                    <input  ref="txtFrom" class="form-control" type="date" v-model="from_date">
                  </div>
                  <label class="col-md-2 col-form-label">Upto</label>
                  <div class="col-md-4">
                    <input class="form-control" type="date" v-model="upto_date">
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-md-2 col-form-label">Author</label>
                  <div class="col-md-10">
                    <select id="cmbAuthor" class="form-control" v-model="author_id" >
                      <option value="0" selected>All</option>
                      <option v-for="ledger in authors" v-bind:value="ledger.id">
                        {{ ledger.name }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-md-2 col-form-label">Publisher</label>
                  <div class="col-md-10">
                    <select class="form-control" v-model="publisher_id" >
                      <option value="0" selected>All</option>
                      <option v-for="ledger in publishers" v-bind:value="ledger.id">
                        {{ ledger.name }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="text-right">
                  <button type="button" class="btn btn-outline-success" @click="loadData" >Show Report<i class="icon-database-refresh ml-2"></i></button>
                </div>

              </div>
            </div>
          </form>
        </vmodal>
      </FocusTrap>

    </div>
  </FocusTrap>
</template>

<script>
import moment from 'moment';
import {userService} from '@/store/auth-header.js'
import ReportViewer from "@/views/tools/ReportViewerByJson";
import { store } from '@/store/store.js'

export default {
  name: 'pendinglist',
  components: {
    ReportViewer,
  },
  store,
  data()  {
    return {
      reppath: '',
      filename: 'pendinglist.mrt',
      ason_date:  moment().format('YYYY-MM-DD'),
      jsondata : {},
      from_date: moment().add(-7,'days').format('YYYY-MM-DD'),
      upto_date: moment().format('YYYY-MM-DD'),
      authors: [],
      publishers: [],
      author_id:0,
      publisher_id:0,
    }
  },
  mounted() {
    const self = this;
    self.$modal.show('filter_window');
    self.loadFilterData();
  },
  methods: {
    afterfilterwindow_visible () {
      this.$data.reppath = '';
      this.$refs.txtFrom.focus();
    },
    showFilterWindow () {
      this.$modal.show('filter_window');
    },
    loadFilterData () {
      const self = this;

      const requestOptions = {
        method: 'GET',
        mode: "cors",
        headers: userService.authHeader()
      };

      self.$data.authors = [];
      fetch(`${process.env.VUE_APP_ROOT_API}api/lms/authors`, requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.ok) {
          if (_.isArray(resp.data)) {
            self.$data.authors = resp.data;
          }
        } else {
          swal({ title: "Oh noes!", text: resp.msg, type: "error" });
        }
      });

      self.$data.publishers = [];
      fetch(`${process.env.VUE_APP_ROOT_API}api/lms/publishers`, requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.ok) {
          if ( _.isArray(resp.data)) {
            self.$data.publishers = resp.data;
          }
        } else {
          swal({ title: "Oh noes!", text: resp.msg, type: "error" });
        }
      }).catch(function (err) {

      });
    },
    loadData(){
      const self = this;

      self.$modal.hide('filter_window');

      const requestOptions = {
        method: 'GET',
        mode:"cors",
        headers: userService.authHeader()
      };

      $('#mycard').block({
        message: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      });

      let filter = `from=${self.$data.from_date}&upto=${self.$data.upto_date}&author_id=${author_id}&publisher_id=${publisher_id}`;
      fetch(`${process.env.VUE_APP_ROOT_API}api/lms/circulations/pending?${filter}`,requestOptions).then(userService.handleResponse).then(function (resp) {
        if(resp.ok) {
          if( _.isArray(resp.data)){
            self.$data.jsondata = resp;
            self.$data.reppath = `${process.env.VUE_APP_ROOT_API}api/lms/circulations/pending?${filter}`;
          }
        } else {
          swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
        }
      }).catch(function (err) {
        swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
      }).finally(function () {
        $('#mycard').unblock();
      });

    }
  }
}
</script>

<style scoped>
input:focus {
  background: #feff00;

}

textarea:focus {
  background: #feff00;

}

select:focus {
  background: #feff00;
}

button:focus {
  background: #feff00;
  color: #0a0a0a !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

img {
  width: 200px;
  height: 300px;
  object-fit: contain;
}

body {
  font-family: 'Roboto', sans-serif;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.blinking-text {
  text-align: center;
  /*margin-top: 20%;*/
  font-size: 24px;
  color: red;
  animation: blink 1s infinite;
}

.bold_input {
  font-size: 24px;
}
</style>
